<template>
  <v-container fluid grey lighten-3 pb-10 pt-10>
    <v-row class="justify-center">
      <v-col cols="8">
        <v-card class="mx-auto" max-width="450">
          <v-card-title>เพิ่ม Tag</v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form" @submit.prevent="submit">
              <v-container>
                <v-row>

                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="tag.name"
                      :rules="nameRules"
                      :counter="50"
                      label="ชื่อ Tag"
                      id="name"
                      maxlength="50"
                    ></v-text-field>
                  </v-col>

                </v-row>
                <v-divider class="mt-5 mb-5"></v-divider>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="gray" class="mr-5" @click="cancel">ยกเลิก</v-btn>
                  <v-btn :disabled="!valid" color="success" type="submit"
                    >บันทึก</v-btn
                  >
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api";

export default {
  name: "TagCreate",
  data() {
    return {
      tag: {
        name: ""
      },
      nameRules: [
        v => !!v || "Name is required",
        v =>
          (typeof v != "undefined" && v.length <= 50) ||
          "Name must be less than 50 characters"
      ],
      valid: true
    };
  },
  methods: {
    async submit() {
      //alert(JSON.stringify(this.tag))
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("name", this.tag.name);
        await api.addTag(formData);
        this.$router.push("/tag-report");
      }
    },
    cancel() {
      this.$router.push("/tag-report");
    },
    reset() {
      this.tag.name = "";
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
